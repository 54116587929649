import { useState, useEffect } from "react";
// import VisibilitySensor from "react-visibility-sensor";
import { InView } from "react-intersection-observer";

function FancyImage({ src = "", className = "", alt = "", style = {}, delay = 0 }) {
  const [visible, setVisible] = useState(false);

  function toggelInView(inview) {
    if (visible === false && inview === true) {
      setTimeout(() => {
        setVisible(true);
      }, 200 + delay);
    }
  }

  useEffect(() => {
    // setAnimationStart(start);
  });

  return (
    <>
      <InView
        as=""
        style={style}
        className={
          visible
            ? `fancy-image fancy-image-inview ${className}`
            : `fancy-image ${className}`
        }
        onChange={(inView) => {
          toggelInView(inView);
          // console.log("in view:" + inView);
        }}
      >
        <img src={src} alt={alt} className="w-full"></img>
      </InView>
    </>
  );
}

export default FancyImage;
