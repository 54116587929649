import { useState } from "react";
import { InView } from "react-intersection-observer";

function FancyDivider({ className = "" }) {
  const [visible, setVisible] = useState(false);

  function toggelInView(inview) {
    if (visible === false && inview === true) {
      setTimeout(() => {
        setVisible(true);
      }, 200);
    }
  }

  return (
    <>
      <InView
        as="div"
        className={className}
        onChange={(inView, entry) => {
          toggelInView(inView);
        }}
      >
        <div
          className={
            visible
              ? "animated-divider animated-divider-inview"
              : "animated-divider"
          }
          onClick={() => {
            toggelInView();
          }}
        ></div>
      </InView>
    </>
  );
}

export default FancyDivider;
