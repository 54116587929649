import React from "react";
import ReactDOM from "react-dom";
import "swiper/css/bundle";
import 'wysiwyg.css'
// import 'react-toastify/dist/ReactToastify.css';
// import "swiper/components/navigation/navigation.scss";
// import "swiper/components/pagination/pagination.scss";
// import "swiper/components/scrollbar/scrollbar.scss";
import "./assets/styles/index.scss";
import AppLayout from "./layouts/AppLayout";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./lib/ScrollToTop";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ScrollToTop />
        <AppLayout />
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
