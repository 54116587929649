import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import FancyText from "../fancyText";
// import FancySlider from "../fancySlider";
import dateFormat from "dateformat";
// import FancyDivider from "../fancyDivider";
import { WorkTypes } from "../../lib/types";
import FancySlider from "../fancySlider";
import FancyDivider from "../fancyDivider";

export default function CarouselCard({ work }: {work:WorkTypes}) {
  const link = "/works/" + work.id + "/" + work.pretty_url;

  return (
    <div className="flex-0">
      <div className="flex flex-wrap mt-8">
        <div className="w-full md:w-1/4 px-2 md:px-4 text-xl h-full">
          <Link to={link} className="underlined link">
            <FancyText tag="h3" className="text-white">
              {work.title}
            </FancyText>
          </Link>
          <div className="mt-2">
            <FancyText tag="p" className="text-brown my-5">
              {work.platform}
            </FancyText>
          </div>
          <p className="hidden md:block text-white">{work.excerpt}</p>
          <p className="text-left hidden md:block mt-10">
            <Link to={link} className="text-left underlined my-4 text-white link">
              View Details
            </Link>
          </p>
        </div>
        <div className="w-full md:w-3/4 flex-0">
          <FancySlider images={work.carousel} alt="myalt" className="flex flex-wrap"/>
        </div>
        <div className="px-2">
          <p className="list-disc pl-4 block md:hidden text-sm text-white"></p>
        </div>
      </div>
      <FancyDivider className="mt-20 px-2 flex flex-wrap"/>
    </div>
  );
}

CarouselCard.defaultProps = {
  id: 0,
  pretty_url: "",
  title: "",
  platform: "",
  date: "",
  excerpt: "",
  carousel: [],
};

CarouselCard.propTypes = {
  id: PropTypes.string,
  pretty_url: PropTypes.string,
  title: PropTypes.string,
  platform: PropTypes.string,
  date: PropTypes.string,
  excerpt: PropTypes.string,
  carousel: PropTypes.array,
};
