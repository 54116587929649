import { Link, Route, Routes } from "react-router-dom";
import icon from "../assets/images/steven-cheak.svg";
import { createContext, useEffect, useRef, useState } from "react";
import Home from "../pages/Home";
import Works from "../pages/Works";
import Work from "../pages/work";
import Articles from "../pages/Articles";
import Article from "../pages/Article";
import logoAnimationData from "../assets/animations/logo.json";
import lottie from "lottie-web";
import { motion } from "framer-motion";

const FirstContext = createContext<{ first: boolean; setFirst: any }>({ first: true, setFirst: () => {} });
export { FirstContext };

const AppLayout = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [first, setFirst] = useState(true);
  const [showPreloader, setShowPreloader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const introAnimation = useRef(null);

  useEffect(() => {
    if (first && introAnimation.current) {
      const animation = lottie.loadAnimation({ container: introAnimation.current, loop: false, animationData: logoAnimationData });

      animation.addEventListener("complete", function () {
        setFirst(false);
        setShowPreloader(false);
      });
    }
  });

  return (
    <FirstContext.Provider value={{ first: first, setFirst: setFirst }}>
      <motion.div animate={{ height: showPreloader ? "100vh" : 0 }} transition={{ duration: 1 }} className="overflow-hidden fixed top-0 left-0 w-screen bg-grey z-50">
        <div className={"w-full md:w-3/5 px-10 mx-auto splash-container"}>
          <div ref={introAnimation} />
        </div>
      </motion.div>

      <div className="w-screen">
        <div className="flex">
          <aside className="h-screen sticky w-16 flex-none top-0 bg-grey hidden md:block side-navigation flex-0">
            <div className="w-full h-screen flex flex-wrap">
              <Link to="" className="w-full no-style">
                <img src={icon} className="p-3 w-full no-style" alt="steve-cheak-icon"></img>
              </Link>
              <svg
                style={{ margin: "0 auto" }}
                className={"menuicon float-right mx-auto my-auto " + (navOpen ? "nav-button nav-open" : "nav-button")}
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 50 50"
                onClick={(e) => {
                  e.preventDefault();
                  setNavOpen(!navOpen);
                }}
              >
                <title>Toggle Menu</title>
                <g>
                  <line className="menuicon__bar" x1="13" y1="20.5" x2="37" y2="20.5" />
                  <line className="menuicon__bar" x1="13" y1="24.5" x2="37" y2="24.5" />
                  <line className="menuicon__bar" x1="13" y1="24.5" x2="37" y2="24.5" />
                  <line className="menuicon__bar" x1="13" y1="28.5" x2="37" y2="28.5" />
                  <circle className="menuicon__circle" r="23" cx="25" cy="25" />
                </g>
              </svg>
            </div>
          </aside>

          <main className="flex-1 bg-grey">
            <motion.div animate={{ opacity: showPreloader ? 0 : 1 }} transition={{ duration: 1 }}>
              <nav className="md:hidden z-50 w-full flex bg-grey h-16 fixed top-0 left-0 overflow-auto">
                <Link to="" className="no-style">
                  <img src={icon} className="p-1 pl-3 w-16 h-16 no-style" alt="steve-cheak-icon"></img>
                </Link>
                <div className="w-full my-auto">
                  <svg
                    className={"menuicon float-right mr-3 " + (navOpen ? "nav-button nav-open" : "nav-button")}
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    onClick={(e) => {
                      e.preventDefault();
                      setNavOpen(!navOpen);
                    }}
                  >
                    <title>Toggle Menu</title>
                    <g>
                      <line className="menuicon__bar" x1="13" y1="20.5" x2="37" y2="20.5" />
                      <line className="menuicon__bar" x1="13" y1="24.5" x2="37" y2="24.5" />
                      <line className="menuicon__bar" x1="13" y1="24.5" x2="37" y2="24.5" />
                      <line className="menuicon__bar" x1="13" y1="28.5" x2="37" y2="28.5" />
                      <circle className="menuicon__circle" r="23" cx="25" cy="25" />
                    </g>
                  </svg>
                </div>
              </nav>

              <section className={"mt-16 md:mt-0 mx-auto " + (navOpen || showModal ? " hidden" : "")}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/works" element={<Works />} />
                  <Route path="/works/:id/:pretty_url" element={<Work />} />
                  <Route path="/blog" element={<Articles />} />
                  <Route path="/blog/:id" element={<Article />} />
                </Routes>
              </section>

              <section className={!showModal || navOpen ? "hidden" : ""}>
                <div className="p-4 pt-0 md:pt-0 w-full mt-60 md:mt-10 max-w-screen-lg mx-auto h-screen flex justify-center">
                  <div className="aspect-video w-full mx-auto my-auto ">
                    <iframe title={"showreel"} width="100%" height="100%" src={"https://player.vimeo.com/video/" + process.env.REACT_APP_SHOWREEL + "?title=0&byline=0&portrait=0&badge=0"} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    <p className="underline text-center mt-5 p-5 cursor-pointer" onClick={() => setShowModal(false)}>
                      close
                    </p>
                  </div>
                </div>
              </section>

              {navOpen && (
                <div style={{ position: "relative" }} className="h-screen">
                  <ul
                    className={navOpen ? "menu-links text-white menu-links-visible w-full text-center absolute text-3xl md:text-5xl my-auto" : "menu-links text-2xl md:text-5xl absolute w-full my-auto"}
                    style={{
                      zIndex: "10",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <li className="mb-4">
                      <Link to={"/"} className="link" onClick={() => setNavOpen(false)}>
                        Home
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link to={"/works"} className="link" onClick={() => setNavOpen(false)}>
                        Works
                      </Link>
                    </li>
                    <li className="mb-4">
                      <Link to={"/blog"} className="link" onClick={() => setNavOpen(false)}>
                        Blog
                      </Link>
                    </li>
                    <li className="mb-4">
                      <a href="mailto:contact@steven-cheak.com" className="link" onClick={() => setNavOpen(false)}>
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </motion.div>
          </main>

          {!navOpen && !showModal && !showPreloader && (
            <button
              onClick={() => {
                if (!navOpen) {
                  setShowModal(!showModal);
                }
              }}
              className={"show-reel-btn-container flex items-center"}
            >
              {!showModal && (
                <svg version="1.1" className="show-reel-btn md:left-3" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 60" xmlSpace="preserve">
                  <style type="text/css"></style>
                  <g>
                    <circle className="show-reel-btn-circle" cx="30" cy="30" r="29" />
                  </g>
                  {!showModal && (
                    <path
                      className={"st1 show-reel-btn-triangle " + (showModal && " st1-red")}
                      d="M45.7,29.2l-22-15c-0.3-0.2-0.7-0.2-1-0.1c-0.3,0.2-0.5,0.5-0.5,0.9v30c0,0.4,0.2,0.7,0.5,0.9
                  C22.8,46,23,46,23.1,46c0.2,0,0.4-0.1,0.6-0.2l22-15c0.3-0.2,0.4-0.5,0.4-0.8S46,29.4,45.7,29.2z"
                    ></path>
                  )}
                  {showModal && <rect x="5" y="5" width="30" height="30" rx="2" ry="2"></rect>}
                </svg>
              )}
              <span className="text-brown mr-3">{!showModal ? "Showreel" : "Close   "}</span>
              {/* {showModal && (
                <div className="m-1 mx-2">
                  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" fill="black" x="0px" y="0px" height={36} width={36} viewBox="0 0 60 60" xmlSpace="preserve">
                    <path
                      d="M42.1,46H17.9c-2.1,0-3.8-1.7-3.8-3.8V17.9c0-2.1,1.7-3.8,3.8-3.8h24.2c2.1,0,3.8,1.7,3.8,3.8v24.2C46,44.2,44.2,46,42.1,46z"
                    />
                  </svg>
                </div>
              )} */}
            </button>
          )}
        </div>
      </div>
    </FirstContext.Provider>
  );
};

export default AppLayout;
