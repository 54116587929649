import { Link } from "react-router-dom";
import dateFormat from "dateformat";
import { sentenceCase } from "change-case";
import { WorkTypes } from "../../lib/types";
import FancyImage from "../fancyImage";
import { buildAssetURI } from "../../lib/uri";

export default function WorkCard({ work }: { work: WorkTypes }) {
  const link = "/works/" + work.id + "/" + work.pretty_url;

  return (
    <div className="my-4 w-full md:w-1/3 px-2" key={work.id}>
      <div className="overflow-hidden shadow-lg card" style={{ boxShadow: "2px 2px 20px 2px #131516" }}>
        <div className="card-image-container overflow-hidden">
          <Link to={link} className="w-full no-style">
            <FancyImage className="aspect-w-16 aspect-h-9 object-cover object-center card-image" src={buildAssetURI(work.image.path)} alt={work.title} />
          </Link>
        </div>
        <div className="flex items-center px-4 py-3 bg-brown">
          <h2 className="text-white font-semibold text-sm">{sentenceCase(work.platform)}</h2>
        </div>
        <div className="py-2 px-4 pb-4 h-full relative">
          <Link to={link} style={{ height: "2em" }}>
            <h3 className="text-2xl font-semibold text-white">{sentenceCase(work.title)}</h3>
          </Link>
          <p className="text-brown text-sm">{dateFormat(work.date, "dd mmmm yyyy")}</p>
          <p className="text-white my-4 mb-10 flex-1">{work.excerpt}</p>
          <p className="text-left absolute bottom-1 md:block mt-4">
            <Link to={link} className="text-left underlined text-white my-4 link">
              View Details
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}
