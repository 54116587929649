/* eslint-disable no-useless-escape */
import { useParams } from "react-router-dom";
import { FacebookIcon, FacebookShareButton, WhatsappIcon, WhatsappShareButton, EmailIcon, EmailShareButton, TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon } from "react-share";
import { sentenceCaseTransform } from "change-case";
import { useEffect, useState } from "react";
import { getArticle } from "../api/articlesAPI";
import Footer from "../components/footer";
import FancyImage from "../components/fancyImage";
import FancyDivider from "../components/fancyDivider";
import FancyText from "../components/fancyText";
import { ArticleTypes } from "../lib/types";
import { buildAssetURI } from "../lib/uri";

function Article() {
  let { id } = useParams();
  const [article, setArticle] = useState<ArticleTypes>();

  useEffect(() => {
    if (id) {
      getArticle(parseInt(id)).then((_data) => {
        setArticle(_data);
        console.log(_data);
      });
    }
  });

  return (
    <div>
      <div>
        <FancyDivider className="md:hidden"></FancyDivider>
        <div className="text-center py-5 md:mb-4 mb-8 w-full md:w-1/2 mx-auto">
          {/* <FancyText tag="h2" className="text-white"> */}
          <h1 className="text-4xl md:text-7xl mt-12 mb-4 md:mt-20 md:mb-12">{article?.title}</h1>
          {/* </FancyText> */}
        </div>
        <div className="relative h-[100vw] md:h-[80vh] overflow-hidden">
          <FancyImage
            className="absolute w-full"
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
            src={buildAssetURI(article?.image.path)}
            alt=""
          />
        </div>
        <div className="flex flex-wrap"></div>
        <div className="mx-auto">
          <div className="mx-4">
            <div className="flex pt-6">
              <div className="mb-2">{/* <h2 className="text-3xl md:text-5xl text-white">{sentenceCaseTransform(article?.title ?? "", 1)}</h2> */}</div>
            </div>
            <div className="flex flex-wrap mb-4 px-1">
              <div className="w-full">
                <h2 className="text-xl text-brown">
                  {/* <Link to="/blog" className="underline">
                    Blog
                  </Link>
                  {" > "}{" "} */}
                  {/* <Link to={"/blog?category=" + article.article_category.pretty_url} className="underline">
                    {article.article_category.title}
                  </Link> */}
                </h2>
              </div>
              {/* <div className="w-full">
                <div className="flex flex-row pb-3 mt-4">
                  <div className="w-auto h-auto rounded-full border-2 border-white">
                    <img className="w-12 h-12 object-cover rounded-full shadow cursor-pointer" alt="User avatar" src={process.env.REACT_APP_BACKEND_URL + article.article_writer.avatar.url} />
                  </div>
                  <div className="flex flex-col mb-2 ml-4 mt-1">
                    <div className="text-white text-sm font-semibold">{article.article_writer.username}</div>
                    <div className="flex w-full mt-1">
                      <div className="text-white font-thin text-xs"> {dateFormat(article.publishedat, "dd mmmm yyyy")}</div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="mx-4 article wysiwyg max-w-[100vw] overflow-hidden" dangerouslySetInnerHTML={{ __html: article?.body.replace('src="/sites', `src=\"${process.env.REACT_APP_API_URL_PREFIX}/sites`) ?? "" }}></div>
          <div className="mt-4 mx-4">
            <WhatsappShareButton url={window.location.href} className="mx-1">
              <WhatsappIcon size={32}></WhatsappIcon>
            </WhatsappShareButton>
            <FacebookShareButton url={window.location.href} className="mx-1">
              <FacebookIcon size={32}></FacebookIcon>
            </FacebookShareButton>
            <TwitterShareButton url={window.location.href} className="mx-1">
              <TwitterIcon size="32"></TwitterIcon>
            </TwitterShareButton>
            <LinkedinShareButton url={window.location.href} className="mx-1">
              <LinkedinIcon size="32"></LinkedinIcon>
            </LinkedinShareButton>
            <EmailShareButton url={window.location.href} className="mx-1">
              <EmailIcon size={32}></EmailIcon>
            </EmailShareButton>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}

export default Article;
