import FancyText from "./fancyText";

function Footer() {
  return (
    <div className="bg-brown mt-20 p-4 pt-20 pb-52 text-white">
      <h4 className="text-white text-4xl mb:text-8xl mb-8"> Let's Talk</h4>
      <a href="mailto:contact@steven-cheak.com">
        <FancyText tag="h3" className="underlined text-left">
          Contact me
        </FancyText>
      </a>
    </div>
  );
}

export default Footer;
