import { Component, createRef } from "react";
import { InView } from "react-intersection-observer";

export default class FancyText extends Component {
  constructor(props) {
    super(props);
    this.stringRef = createRef();
    this.state = { visible: false };
    this.showText = this.showText.bind(this);
    this.prepareText = this.prepareText.bind(this);
    this.getRow = this.getRow.bind(this);
  }

  showText(inview) {
    if (this.state.visible === false && inview === true) {
      setTimeout(() => {
        this.setState({ visible: true });
      }, (200+this.props.delay));
    }
  }

  prepareText() {
    let t = this.stringRef.current.textContent,
      i = [];
      // console.log(t.length)
    for (; t.length > 0; ) {
      let e = this.getRow(t);
      i.push(e);
      t = t.substring(e.length, t.length);
    }
    // console.log(this.stringRef.current.offsetWidth,this.stringRef.current.scrollWidth);
    this.stringRef.current.innerHTML = "";
    i.forEach((t) => {
      let i = document.createElement("span");
      i.classList.add("animated-text-wrapper");
      let e = document.createElement("span");
      e.classList.add("animated-text-row");
      e.textContent = t;
      i.appendChild(e);
      this.stringRef.current.appendChild(i);
    });
  }

  getRow(t) {
    for (
      this.stringRef.current.textContent = t;
      this.stringRef.current.scrollWidth > this.stringRef.current.offsetWidth;

    ) {
      let i = t.lastIndexOf(" ");
      t = t.substring(0, i);
      this.stringRef.current.textContent = t;
    }
    return this.stringRef.current.textContent;
  }

  componentDidMount() {
    this.prepareText();
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    return (
      <InView
        className={this.props.containerClassName+" link"}
        onChange={(inView) => {
          this.showText(inView);
        }}
      >
        <this.props.tag
          className={
            "animated-text " +
            (this.state.visible && " animated-text-inview ") +
            this.props.className
          }
          ref={this.stringRef}
        >
          {this.props.children}
        </this.props.tag>
      </InView>
    );
  }
}

FancyText.defaultProps = {
  tag: "p",
  text: "Default Text",
  className: "",
  containerClassName: "",
  delay: 0
};
