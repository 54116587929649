import { useParams, Link } from "react-router-dom";
import { FacebookShareButton, FacebookIcon, WhatsappIcon, EmailShareButton, EmailIcon, WhatsappShareButton, TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon } from "react-share";
import Footer from "../components/footer";
import dateFormat from "dateformat";
import { useEffect, useState } from "react";
import { getWork, getWorks } from "../api/worksAPI";
import { WorkTypes } from "../lib/types";
import FancyDivider from "../components/fancyDivider";
import FancyText from "../components/fancyText";
import FancyImage from "../components/fancyImage";
import { buildAssetURI } from "../lib/uri";

function Work() {
  const [next, setNext] = useState<WorkTypes>();
  const [prev, setPrev] = useState<WorkTypes>();
  const [data, setData] = useState<WorkTypes>();
  let { id } = useParams();
  const [current, setCurrent] = useState(id);

  useEffect(() => {
    console.log(id);
    getWork(parseInt(id ?? "0")).then((_data) => {
      setData(_data);
      getWorks().then((res) => {
        const ids = res?.map((_work: WorkTypes) => {
          return _work.id;
        });
        var indexOF = ids?.indexOf(id);
        if (ids[indexOF + 1] !== undefined) {
          setNext(res[indexOF + 1]);
        } else {
          setNext(undefined);
        }
        if (ids[indexOF - 1] !== undefined) {
          setPrev(res[indexOF - 1]);
        } else {
          setPrev(undefined);
        }
      });
    });
  }, [id, next, prev]);

  return (
    <div id={current}>
      <div className="">
        <FancyDivider />
        <div
          className="flex bg-fixed relative"
          style={{
            backgroundImage: `url(${buildAssetURI(data?.image.path)})`,
            backgroundSize: "cover",
          }}
        >
          <div
            className="h-screen w-full"
            style={{
              background: "rgba(19, 21, 22,.6)",
              minHeight: "600px",
            }}
          >
            <div className="w-full slide__number">
              <div className="text-center w-full max-w-sm mx-auto mt-6">
                {/* {prev && (
                  <Link to={"/works/" + prev.id + "/" + prev.pretty_url} className="float-left text-white arrow-link left-arrow" onClick={() => setCurrent(prev.id)}>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 40 60" style={{ width: "40px", height: "40px" }} xmlSpace="preserve">
                      <style type="text/css"></style>
                      <title>landfancy-left_1</title>
                      <g>
                        <g id="Layer_1-2">
                          <polygon className="st0" points="37,60 40,58 10,30 40,2 37,0 0,30 		" />
                        </g>
                      </g>
                    </svg>
                  </Link>
                )} */}
                <div className="inline-block">
                  <FancyText tag="h3" className="text-white">
                    {/* #<ZeroPad num={work.id} places={2}></ZeroPad> */}
                  </FancyText>
                </div>

                {/* {next && (
                  <Link to={"/works/" + next.id + "/" + next.pretty_url} className="float-right text-white arrow-link right-arrow" onClick={() => setCurrent(next.id)}>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 40 60" xmlSpace="preserve" style={{ width: "40px", height: "40px" }}>
                      <style type="text/css"></style>
                      <title>landfancy-right</title>
                      <g>
                        <g id="Layer_1-2">
                          <polygon className="st0" points="3,60 0,58 30,30 0,2 3,0 40,30 		" />
                        </g>
                      </g>
                    </svg>
                  </Link>
                )} */}
              </div>
            </div>
            <div className="text-white absolute text-center" style={{ left: 0, right: 0, margin: "auto", top: "45vh" }}>
              <FancyText tag="h4" className="underlined text-center md:text-7xl text-4xl mx-auto" spacing="text-center">
                {data?.title}
              </FancyText>
            </div>
            <div className="downArrow bounce text-center text-xl" style={{ left: 0, right: 0, margin: "auto" }}>
              <p className="text-center">Scroll Down</p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap-reverse w-full mt-4">
          <div className="w-full md:w-3/4 px-4">
            <p className="text-xl my-10">{data?.description}</p>

            <FancyDivider className="py-4"></FancyDivider>
            <div className="flex flex-wrap w-full">
              {data?.images?.map((image, i: number) => {
                return (
                  <div className="w-full md:w-1/2 overflow-hidden my-2 md:p-5 md:my-10">
                    <FancyImage key={i} className="w-full md:py-4 pt-0" src={buildAssetURI(image.path)} alt={data?.title} />
                  </div>
                );
              })}
            </div>
            {data?.vimeo_video?.map((code: string, i: number) => {
              return (
                <div className="w-full py-2 md:py-10 md:pt-0" key={i}>
                  <div
                    className="aspect-video w-full "
                    style={{
                      backgroundImage: `url(${buildAssetURI(data?.image.path)})`,
                      backgroundSize: "cover",
                    }}
                  >
                    <iframe title={code} width="100%" height="100%" src={"https://player.vimeo.com/video/" + code + "?title=0&byline=0&portrait=0&badge=0"} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </div>
                </div>
              );
            })}
            {data?.youtube_video?.map((code: string, i: number) => {
              return (
                <div className="w-full py-2 md:py-10 md:pt-0" key={i}>
                  <div
                    className="aspect-video w-full"
                    style={{
                      backgroundImage: `url(${buildAssetURI(data?.image.path)})`,
                      backgroundSize: "cover",
                    }}
                  >
                    <iframe allowFullScreen width="100%" height="100%" title={code} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" className="w-full" src={"https://www.youtube.com/embed/" + code + "?rel=0&controls=0&showinfo=0"}></iframe>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="w-full md:w-1/4 px-4">
            <p className="text text-xs text-brown">Platform</p>
            <p className="text-xl">{data?.platform}</p>

            <p className="mt-4 text-xs text-brown">Year</p>
            <p className="text-xl">{dateFormat(data?.date, "dd mmmm yyyy")}</p>

            <p className="text-sm mt-4 text-brown">Credits</p>
            {data?.credits?.split("\n").map((role: string) => {
              return <p className="text-xl">{role}</p>;
            })}
            <div className="ml-0 mt-4">
              <WhatsappShareButton url={window.location.href} className="mx-1">
                <WhatsappIcon size={32}></WhatsappIcon>
              </WhatsappShareButton>
              <FacebookShareButton url={window.location.href} className="mx-1">
                <FacebookIcon size={32}></FacebookIcon>
              </FacebookShareButton>
              <TwitterShareButton url={window.location.href} className="mx-1">
                <TwitterIcon size="32"></TwitterIcon>
              </TwitterShareButton>
              <LinkedinShareButton url={window.location.href} className="mx-1">
                <LinkedinIcon size="32"></LinkedinIcon>
              </LinkedinShareButton>
              <EmailShareButton url={window.location.href} className="mx-1">
                <EmailIcon size={32}></EmailIcon>
              </EmailShareButton>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}

export default Work;
