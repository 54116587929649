import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getWorks } from "../api/worksAPI";
import WorkCard from "../components/cards/workCard";
import FancyText from "../components/fancyText";
import Footer from "../components/footer";
import { FirstContext } from "../layouts/AppLayout";
import { WorkTypes } from "../lib/types";
import { buildAssetURI } from "../lib/uri";

var ellipsis = require("text-ellipsis");

function Works() {
  const [featured, setFeatured] = useState<WorkTypes>();
  const [works, setWorks] = useState<Array<WorkTypes>>([]);
  const { first } = useContext(FirstContext);

  useQuery("works", () => getWorks(), {
    onSuccess: (res) => {
      const featured = res
        .filter((work: WorkTypes) => {
          return work.featured;
        })
        .pop();
      setFeatured(featured);
      setWorks(
        res.filter((_t: WorkTypes) => {
          return _t.id !== featured.id;
        })
      );
    },
  });
  return (
    <div>
      {featured && (
        <div className="card">
          <div
            className="text-center md:text-left w-full px-2 md:pl-4 card-bg featured_work"
            style={{
              position: "relative",
              overflow: "hidden",
              height: "85vh",
              minHeight: "400px",
              backgroundImage: `url(${buildAssetURI(featured.image.path)})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              boxShadow: "inset 0 0 0 1000px rgba(49, 47, 48,.2)",
            }}
          >
            <div className="ribbon">
              <h4>Featured Project</h4>
            </div>

            <div>
              <div style={{ marginTop: "35vh" }} className="ml-0 md:ml-20">
                <Link className="pr-3 md:pr-4 mb-10" to={"/works/" + featured.id + "/" + featured.pretty_url}>
                  {/* <FancyText tag="h1" className="text-white" delay={isFirstMount ? 3000 : 0}> */}
                  <FancyText tag="h1" className="text-white" delay={first ? 3000 : 0}>
                    {ellipsis(featured.title, 250, { ellipsis: " " })}
                  </FancyText>
                </Link>
                <div className="pr-3 md:pr-4">
                  {/* <FancyText tag="h3" delay={isFirstMount ? 3000 : 0} className="text-brown"> */}
                  <Link className="pr-3 md:pr-4 mb-10" to={"/works/" + featured.id + "/" + featured.pretty_url}>
                    <FancyText tag="h3" delay={first ? 3000 : 0} className="text-white cursor-pointer">
                      {featured.platform}
                    </FancyText>
                  </Link>
                </div>
              </div>
            </div>

            <p style={{ position: "absolute" }} className="bottom-10 left-0 md:left-4 w-full"></p>
          </div>
        </div>
      )}

      <div className="flex flex-wrap mx-2 md:mx-20 min-h-screen mt-0 md:mt-10">
        {works.map((_work: WorkTypes, i: number) => {
          return <WorkCard key={i} work={_work} />;
        })}
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Works;
