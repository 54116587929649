import { axiosInstance } from "../lib/API";

const BASE_PATH = "api/collections";

async function getFrontPageWorks() {
  return await axiosInstance.get(`${BASE_PATH}/get/work`).then((_res) => {
    const tmp = _res.data.entries.map((entry:any, i:any) => {
      entry.id = i
      return entry;
    });
    return tmp
  });
}

async function getWork(id: number) {
  return await axiosInstance.get(`${BASE_PATH}/get/work`).then((_res) => {
    const tmp = _res.data.entries.map((entry:any, i:any) => {
      entry.id = i
      return entry;
    });
    return tmp[id]
  });
}
async function getWorks() {
  return await axiosInstance.get(`${BASE_PATH}/get/work`).then((_res) => {
    const tmp = _res.data.entries.map((entry:any, i:any) => {
      entry.id = i
      return entry;
    });
    return tmp
  });
}

function mapWork(res: any) {
  var _formatted = res?.data?.data?.map((_data: any) => {
    var carousel_ids = _data.relationships.field_carousel.data.map((_data: any) => {
      return _data.id;
    });
    var image_ids = _data.relationships.field_images.data.map((_data: any) => {
      return _data.id;
    });
    return {
      id: _data.id,
      pretty_url: _data.attributes.field_pretty_url,
      title: _data.attributes.title,
      platform: _data.attributes.field_platform,
      featured: _data.attributes.field_featured_work,
      date: _data.attributes.field_date,
      excerpt: _data.attributes.field_excerpt,
      description: _data.attributes.field_description,
      credits: _data.attributes.field_credits,
      vimeo_video_codes: _data.attributes.field_vimeo_video_codes,
      youtube_video_codes: _data.attributes.field_youtube_video_codes,
      banner: res?.data?.included
        ?.filter((_data1: any) => {
          return _data1.id === _data?.relationships?.field_banner_image?.data?.id;
        })
        ?.map((_data: any) => {
          return process.env.REACT_APP_API_URL_PREFIX + _data.attributes.uri.url;
        }),
      tmp: _data?.relationships?.field_banner_image?.data?.id,
      carousel: res?.data?.included
        .filter((_data: any) => {
          return carousel_ids.includes(_data.id);
        })
        .map((_data: any) => {
          return process.env.REACT_APP_API_URL_PREFIX + _data.attributes.uri.url;
        }),
      images: res?.data?.included
        .filter((_data: any) => {
          return image_ids.includes(_data.id);
        })
        .map((_data: any) => {
          return process.env.REACT_APP_API_URL_PREFIX + _data.attributes.uri.url;
        }),
    };
  });
  return _formatted;
}

export { getFrontPageWorks, mapWork, getWorks, getWork };
