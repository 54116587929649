import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getArticles } from "../api/articlesAPI";
import FancyDivider from "../components/fancyDivider";
import FancyImage from "../components/fancyImage";
import FancyText from "../components/fancyText";
import Footer from "../components/footer";
import { ArticleTypes } from "../lib/types";
import { buildAssetURI } from "../lib/uri";

function Articles() {
  //   let category = query.get("category");
  const { data } = useQuery("articles", () => getArticles());

  return (
    <div>
      <FancyDivider className="md:hidden"></FancyDivider>
      <div className="text-center py-5 md:mb-4 mb-8 w-full md:w-1/2 mx-auto">
          {/* <FancyText tag="h2" className="text-white"> */}
          <h1 className="text-4xl md:text-7xl mt-12 mb-6 md:mt-20 md:mb-12">Articles</h1>
          {/* </FancyText> */}
        </div>
      {/* <FancyDivider className="md:block my-2"></FancyDivider> */}
      <div className="flex flex-wrap"></div>

      <div className="flex flex-wrap mt-2 min-h-screen">
        {data?.map((article: ArticleTypes, i: number) => {
          const link = "/blog/" + article.id;
          return (
            <div className="w-full md:w-1/4" key={i}>
              <div className="mx-4 mb-8">
                <div className="flex max-w-xl my-2 mt-0 bg-dark-grey overflow-hidden mx-auto card group" style={{ boxShadow: "1px 1px 20px 1px #131516" }}>
                  <div className="flex items-center w-full">
                    <div className="w-full">
                      <div className="card-image-container overflow-hidden relative" style={{backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,1))'}}>
                        <Link to={link} className="w-full nostyle opacity-50 group-hover:opacity-100 duration-300">
                          <FancyImage className="w-full aspect-square object-cover object-center card-image " src={buildAssetURI(article.image.path)} alt="" />
                        </Link>
                        <div className="text-white text-lg mx-2 px-2 my-8 absolute bottom-0 left-0 w-3/5">
                          <Link to={link} className="nostyle text-xl font-medium duration-300">
                            {article.title}
                          </Link>
                        </div>
                      </div>

                      {/* <AnimatedDivider classes="my-3"></AnimatedDivider> */}
                      {/* <div className="flex flex-row px-2 pb-3 mx-2">
                        <div className="w-auto h-auto rounded-full border-2 border-white">
                          <img className="w-12 h-12 object-cover rounded-full shadow cursor-pointer" alt="User avatar" src={process.env.REACT_APP_BACKEND_URL + article.article_writer.avatar.url} />
                        </div>
                        <div className="flex flex-col mb-2 ml-4 mt-1">
                          <div className="text-white text-sm font-semibold">{article.article_writer.username}</div>
                          <div className="flex w-full mt-1">
                            <div className="text-brown font-base text-xs mr-1 cursor-pointer">{article.article_category.title}</div>
                            <div className="text-white font-thin text-xs">• {dateFormat(article.publishedat, "dd mmmm yyyy")}</div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Articles;
