import { axiosInstance } from "../lib/API";

const BASE_PATH = "api/collections";

async function getArticles() {
  return await axiosInstance.get(`${BASE_PATH}/get/article`).then((_res) => {
    const tmp = _res.data.entries.map((entry:any, i:any) => {
      entry.id = i
      return entry;
    });
    return tmp
  });
}

async function getArticle(id: number) {
  return await axiosInstance.get(`${BASE_PATH}/get/article`).then((_res) => {
    const tmp = _res.data.entries.map((entry:any, i:any) => {
      entry.id = i
      return entry;
    });
    return tmp[id]
  });
}

function mapArticle(res: any) {
  var _formatted = res?.data?.data?.map((_data: any) => {
    return {
      id: _data.id,
      title: _data.attributes.title,
      body: _data.attributes.body.processed,
      banner: res?.data?.included
        ?.filter((_data1: any) => {
          return _data1.id === _data?.relationships?.field_image?.data?.id;
        })
        ?.map((_data: any) => {
          return process.env.REACT_APP_API_URL_PREFIX + _data.attributes.uri.url;
        }),
    };
  });
  return _formatted;
}

export { getArticles, getArticle };
