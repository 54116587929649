import axios from 'axios';
// import { logoutUser } from '../services/auth.service';
// import { getAuthToken } from '../services/auth.service';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  config => {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
    };
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// axiosInstance.interceptors.response.use(
//   response => {
//     return response;
//   },
//   error => {
//     if (error?.response?.status === 401) {
//       logoutUser();
//       // exempt login and register from  redirect 
//       if (
//         error?.response?.config.url !== 'auth/login' &&
//         error?.response?.config.url !== 'auth/register'
//       ) {
//         window.location.href = '/auth/login';
//       }
//     }
//     return Promise.reject(error);
//   }
// );

export { axiosInstance };