import b from "../assets/images/parallax/phone/2.svg";
import c from "../assets/images/parallax/phone/3.svg";
import d from "../assets/images/parallax/phone/4.svg";
import { useRef, useEffect, useContext } from "react";
import Parallax from "parallax-js";
import FancyText from "../components/fancyText";
import { Link } from "react-router-dom";
import Footer from "../components/footer";
import { useQuery } from "react-query";
import { getFrontPageWorks } from "../api/worksAPI";
import CarouselCard from "../components/cards/carouselCard";
import { WorkTypes } from "../lib/types";
import { FirstContext } from "../layouts/AppLayout";

function Home() {
  const sceneEl = useRef(null);
  const componentRef = useRef(null);
  const { data } = useQuery("selectedWorks", () => getFrontPageWorks());
  const { first } = useContext(FirstContext);

  useEffect(() => {
    console.log(data)
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
      clipRelativeInput: true,
      scalarX: 20,
      scalarY: 20,
      limitX: 50,
      limitY: 50,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, [data]);

  return (
    <div className="w-screen">
      <div className="h-screen bg-white relative overflow-hidden">
        <div className="">
          <div id="scene" ref={sceneEl}>
            <div data-depth="1.00" data-limit-x="10">
              <img className="h-screen" style={{ transform: "scale(1.5)", minWidth: "230vh" }} src={d} alt="" />
            </div>
            <div data-depth="0.80">
              <img className="h-screen slideInFromLeft" style={{ transform: "scale(1.5)", minWidth: "120vh" }} src={c} alt="" />
            </div>
            <div data-depth="0.60">
              <img className="h-screen" style={{ transform: "scale(1.5)", minWidth: "120vh" }} src={b} alt="" />
            </div>
            <div data-depth="0.40"></div>
          </div>
        </div>
        <div className="absolute flex items-center justify-center h-screen mb-12 overflow-hidden top-0" style={{ background: "rgba(49, 47, 48,.1)" }}>
          <div className="relative p-5 text-white mt-52 pl-0 md:pl-20">
            <div className="mb-3 w-screen">
              <FancyText tag="h1" delay={first ? 3000 : 0}>
                Steven Cheak
              </FancyText>
            </div>

            <section className="wrapper text-white">
              <h2 className="sentence">
                <span className="text-white">Director |</span>{" "}
                <div className="slidingText text-white text-center">
                  <span>Creative </span>
                  <span>Branding </span>
                  <span className="text-center">Digital </span>
                  <span ref={componentRef}>Branding </span>
                </div>
              </h2>
            </section>
          </div>
        </div>
      </div>

      <section className="pl-0 md:pl-20">
        <div className="flex flex-wrap w-full mb-10 px-2 md:px-4 md:pt-20 pt-10">
          <div className="w-full md:w-5/6 md:mt-6 text-white">
            <div className="mb-5">
              <FancyText tag="h4">I am a Creative, Branding and Digital Director.</FancyText>
            </div>
            <div className="mb-5">
              <FancyText tag="h4">Enhance businesses - with ideas to improve user experience, brand equity and bottom line.</FancyText>
            </div>
            <div className="mb-5">
              <FancyText tag="h4" className="mb-5">
                Bridging the gap - between the first (TV) and second screens (Mobile and Desktop).
              </FancyText>
            </div>
            <div className="mb-5">
              <FancyText tag="h4" className="mb-5">
                Maintaining the brand – across multiple touchpoints.
              </FancyText>
            </div>
            <div className="mb-5">
              <FancyText tag="h4" className="mb-5">
                Impacting audiences - with award-winning creatives in MENA, Asia and Europe since 2000.
              </FancyText>
            </div>
            <div className="mb-5">
              <FancyText tag="h4" className="mb-5">
                Use both sides of the brain – A Creative with an MBA.
              </FancyText>
            </div>
          </div>
        </div>

        <div className="flex">
          <div className="w-full md:w-3/5">
            <div className="my-10 mb-16 md:pl-4 pl-2">
              <FancyText tag="h2" className="text-brown">
                Selected Works
              </FancyText>
            </div>
          </div>
        </div>
        {data?.map((work: WorkTypes, i: any) => {
          return <CarouselCard key={i} work={work}></CarouselCard>;
        })}
      </section>

      <div className="text-center mt-20 md:mt-20 text-white">
        <Link to="works" className="underlined text-center link">
          <FancyText tag="h4" className="underlined text-center" spacing="text-center">
            View More Work
          </FancyText>
        </Link>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Home;
