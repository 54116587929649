import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { InView } from "react-intersection-observer";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const FancySlider = ({ className = "", pagination = true, images = {}, alt = "" }) => {
  const [visible, setVisible] = useState(false);
  const [dx, setDx] = useState(0);
  const [holding, setHolding] = useState("");
  const [id] = useState(uuidv4());

  function toggelInView(inview) {
    if (visible === false && inview === true) {
      setTimeout(() => {
        setVisible(true);
      }, 200);
    }
  }

  return (
    <InView
      as="div"
      className={className}
      onChange={(inView) => {
        toggelInView(inView);
      }}
    >
      <Swiper
        className="w-screen"
        spaceBetween={5}
        pagination={
          pagination && {
            clickable: true,
            el: `.my${id}`,
          }
        }
        direction="horizontal"
        preventClicks={true}
        slidesPerView={1.2}
        freeModeMomentum={true}
        freeModeMomentumRatio={0.4}
        freeModeMomentumVelocityRatio={0.8}
        speed={300}
        loop={true}
        longSwipesRatio={0.3}
        freeMode={false}
        lazy={false}
        breakpoints={{
          480: {
            slidesPerView: 1.4,
            spaceBetween: 10,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 2.6,
            spaceBetween: 40,
          },
        }}
        onTouchStart={(swiper, event) => {
          setHolding("holding");
          // console.log(event);
        }}
        onTouchMove={(swiper, event) => {
          setDx(Math.sign(swiper.touches.diff));
        }}
        onTouchEnd={(swiper, event) => {
          setHolding("released");
          setDx(0);
        }}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            {({ isActive }) => (
              <div className={"overflow-hidden image-container " + (holding === "holding" ? "image-container-holding " : "") + (dx > 0 && holding === "holding" ? " image-container-left" : "") + (dx < 0 && holding === "holding" ? " image-container-right" : "")}>
                <div className={isActive ? "slider-blurred slider-not-blurred " : "slider-blurred "}>
                  <img className={"slider-image " + (visible && "slider-image-inview ") + (holding === "holding" && " slider-image-holding ") + (dx > 0 && holding === "holding" ? "slider-image-left" : "") + (dx < 0 && holding === "holding" ? "slider-image-right" : "")} src={process.env.REACT_APP_API_URL_PREFIX + image.path} alt={alt} />
                </div>
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={pagination && "w-full text-center mt-6"}>
        <div className={pagination && `my${id} mx-auto inline-block`}></div>
      </div>
    </InView>
  );
};

export default FancySlider;
